import React, { useState } from 'react'
import { FaArrowLeft, FaFacebook, FaHandshake, FaRegHeart, FaTree, FaWhatsapp } from "react-icons/fa6";
import SwiperClients from './SwiperClients';
import Services from './Services';
import Steps from './Steps';
import Marks from './Marks';
import MessageLeadForm from './MessageLeadForm';
import { IoIosCall, IoLogoFacebook, IoLogoInstagram, IoLogoYoutube } from 'react-icons/io';
import { IoArrowDown, IoCall, IoCallSharp, IoLogoTiktok } from 'react-icons/io5';
import TiktokGrid from './TiktokGrid';
import logo from "../images/moovemedia_logo.png"
import Stav from "../images/stav.png"
import Arrows from "../images/arrows.png"
import LeadForm from './LeadForm';
import { SiSololearn } from 'react-icons/si';
import { PiBrainBold } from 'react-icons/pi';
import { MdAutoFixHigh } from 'react-icons/md';
import InfoCard from './InfoCard';
import { BsStar } from 'react-icons/bs';


const HomePage = () => {

    const [isDialogOpen, setDialogOpen] = useState(false);

    const openDialog = () => {
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setDialogOpen(false);
    };

    return (
        <div className='heroBg'>

            <section className=' py-20 md:h-screen  justify-center my-container items-center flex'>
                <div className='px-4'>


                    <div dir='rtl' className='flex items-center justify-center text-center  '>
                        <div>
                            <div className='flex-col  flex'>
                                <img data-aos="fade-down"
                                    data-aos-easing="ease-in-out" src={logo} className='w-[350px] px-4 mx-auto' alt="logo" />
                                <h1
                                    data-aos="fade-down"
                                    data-aos-easing="ease-in-out"
                                    className='text-3xl md:text-3xl lg:text-5xl w-full font-bold text-gray-200 max-w-[1000px]   p-4 rounded-3xl'>
                                    יש לי
                                    <span className='text-secondary'> שיטה </span>
                                    <br />
                                    שהופכת מתעניינים
                                    <br />
                                    <span className='text-secondary und'>ללקוחות משלמים  </span>
                                </h1>

                                <div
                                    onClick={openDialog}
                                    className='mt-4'>
                                    <a className=' flex max-w-[250px] lg:max-w-max lg:p-6 lg:text-5xl  hover:text-secondary shadow-2xl justify-center cursor-pointer  items-center px-2 py-2  text-xl  mx-auto rounded-full border-secondary border-2 text-gray-200 bg-gradient-to-tr from-black to-transparent  font-bold hover:scale-105 duration-300'>
                                        <h1 className='px-4'>
                                            לפרטים נוספים                                        </h1>
                                        <FaArrowLeft className='ml-2 text-secondary' />

                                    </a>
                                </div>
                                
                                {/* <h1
                                    data-aos="fade-in"
                                    data-aos-easing="ease-in-out"
                                    data-aos-delay="1000"
                                    className='text-xl md:text-3xl my-2 font-bold text-secondary'>
                                    הגעתם למקום הנכון!
                                </h1> */}
                            </div>

                            {/* <div className='my-4'>
                                <h1 data-aos="fade-down"
                                    data-aos-delay="500"
                                    data-aos-easing="ease-in-out" className='text-5xl md:text-7xl font-bold text-main'>
                                    סתיו סמדר
                                </h1>
                                <h1 data-aos="fade-down"
                                    data-aos-delay="1000"
                                    data-aos-easing="ease-in-out" className='text-2xl md:text-4xl mt-2 font-bold text-secondary'>
                                    עורך וידאו עם למעלה מ 7 שנות נסיון
                                </h1>
                            </div> */}
                        </div>

                    </div>
                    <div className='max-w-[300px] mx-auto'>
                        {/* <a href='#form' className='flex shadow-2xl justify-center cursor-pointer  items-center px-4 py-2 my-4 text-xl  mx-auto rounded-full text-dark bg-gradient-to-tr from-secondary tp bg-yellow-300  font-bold hover:scale-105 duration-300'>
                            <FaArrowLeft className='mr-2' />
                            <h1>
                                דברו איתנו
                            </h1>
                        </a> */}

                        <a href="https://wa.me/972546966909?text=היי%20סתיו,%20הגעתי%20דרך%20הדף.%20אשמח%20לפרטים%20נוספים%20בנוגע%20לשיווק%20דיגיטלי%20לעסק."
                            className="flex max-w-[250px] mt-6  justify-center cursor-pointer items-center shadow-2xl border-green-300 border-2 shadow-green-300 px-2 py-2  text-xl mx-auto rounded-full text-main bg-gradient-to-tr from-green-600 to-transparent font-bold hover:scale-105 duration-300">
                            <FaWhatsapp size={30} className="mr-2 text-green-200" />
                            <h1 dir='rtl'>
                                לשיחת Whatsapp                            </h1>
                        </a>

                    </div>
                </div>
            </section>


            <section className='specialBg rounded-t-3xl py-20 '>
                <div className='px-4'>
                    {/* <h1 data-aos="fade-down"
                        data-aos-easing="ease-in-out" dir='rtl' className='text-center text-2xl md:text-4xl font-extrabold text-main'>
                        החוזקות שלי
                    </h1>
                    <h1 data-aos="fade-down"
                        data-aos-easing="ease-in-out" dir='rtl' className='text-center text-2xl md:text-4xl font-extrabold text-secondary'>
                        כעורך וידאו
                    </h1> */}
                    {/* <Services /> */}

                    {/* <h1 data-aos="fade-down"
                        data-aos-easing="ease-in-out" dir='rtl' className='text-center text-2xl md:text-4xl font-extrabold text-secondary '>
                        קצת עליי
                    </h1> */}
                    <p dir='rtl' className="text-xl px-2 md:text-2xl font-medium mt-4 text-gray-200 text-center  max-w-3xl mx-auto">
                        שמי סתיו סמדר והקמתי את Moove media למענך!
                      
                        לעזור לך להגדיל את הרווחים בעסק בקלות ומהירות.
                        
                       
                        השקעתי שנים רבות בהבנה שיווקית ופסיכולוגיה צרכנית.
                        <br />
                        השיטה שלי היא לא שיטה חדשה, ולא אני המצאתי אותה.
                        אבל זאת שיטה מנצחת שעובדת ותעבוד כנראה תמיד.
                        <div className='mt-2'>
                        </div>
                        <span className='font-extrabold text-secondary'>
                            אני יכול להבטיח לך שבפגישה אחת של 20 דקות אדע אם אני יכול
                            לעזור לך להביא לקוחות איכותיים משלמים שכיף לעבוד איתם.
                        </span>

                    </p>

                </div>

                <div className="flex justify-center mt-12  text-white">
                    <a href="tel:+972546966909" className=" flex px-6 py-4 hover:text-secondary shadow-2xl justify-center cursor-pointer  items-center   text-xl  mx-auto rounded-full border-secondary border-2 text-main bg-gradient-to-tr from-black to-transparent  font-bold hover:scale-105 duration-300">
                        <IoCallSharp  className='text-4xl mr-4 text-secondary' />
                        <span className='text-xl md:text-2xl'> לחצו כאן לשיחת ייעוץ</span>
                    </a>
                </div>
            </section>


            <section>


                <div className="bg-gradient-to-tr my-container   p-4 ">
                    <h1 dir="rtl" className="text-5xl md:text-5xl text-center text-gray-200 font-extrabold mt-12">למה דווקא
                        <br />
                        <span dir='ltr'>
                            moove media?
                        </span>

                    </h1>

                    <div className="flex justify-center items-center my-12   mx-8 ">
                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 rounded-2xl gap-8 max-w-[1200px]  ">
                            <InfoCard icon={SiSololearn} title="שיווק מבוסס אסטרטגיה" description="יצירת תכניות שיווק מותאמת אישית להשגת תוצאות מיטביות." />
                            <InfoCard icon={FaHandshake} title="ליווי אישי" description="תמיכה וליווי צמוד לאורך כל התהליך השיווקי" />
                            <InfoCard icon={PiBrainBold} title="התמחות בפיתוח מותג" description="עיצוב ופיתוח מותג חזק שיבלוט בשוק וימשוך את קהל היעד הנכון." />
                            <InfoCard icon={MdAutoFixHigh} title="פורצים את הגבולות בעסק" description="מיטוב פוטנציאל, ומקסום המכירות בעסק" />
                            <InfoCard icon={BsStar} title="7+ שנות נסיון" description="יותר משבע שנות ניסיון בעולמות השיווק והמותג,  הצלחות מרשימות ולקוחות מרוצים.





" />
                        </div>
                    </div>
                </div>
            </section>

            <section className='specialBg specialBg py-20 pt-0 '>
                <div className='px-4'>

                    <img data-aos="fade-down"
                        data-aos-easing="ease-in-out" src={Stav} className='w-[350px] mx-auto ' alt="stav" />

                    <div
                        onClick={openDialog}

                        className='max-w-[300px] mx-auto mt-[-55px]'>
                        <div className='text-float'>
                            <a dir='rtl' className=' text-main border-2 border-secondary shadow-secondary hover:text-secondary hover:scale-105  flex flex-col shadow-2xl justify-center cursor-pointer  text-center items-center p-6 my-4 text-xl  mx-auto rounded-3xl  bg-gradient-to-tr from-black to bg-[#333]   font-bold duration-300'>
                                <h1 className=' '>
                                    אני רוצה לשווק את העסק שלי בשיטה שתגרום ללקוחות לשלם לי בבטחון
                                </h1>
                                <FaArrowLeft className='mr-2 text-secondary' />

                            </a>
                        </div>

                    </div>
                    <h1 data-aos="fade-down"
                        data-aos-easing="ease-in-out" dir='rtl' className='text-center text-2xl md:text-4xl mt-8 font-extrabold text-secondary '>
                        מה בפועל קורה בשיטה של <br /> Moove-media
                    </h1>

                    <IoArrowDown size={65} className='mx-auto mt-4 text-secondary text-zoom' />


                    <h1 data-aos="fade-down"
                        data-aos-easing="ease-in-out" dir='rtl' className='text-center text-2xl md:text-4xl font-extrabold text-main mt-8'>
                        אנחנו שמים דגש
                    </h1>
                    <h1 data-aos="fade-down"
                        data-aos-easing="ease-in-out" dir='rtl' className='text-center text-2xl md:text-4xl font-extrabold text-secondary'>
                        על 2 היבטים עיקריים                       </h1>
                    <div dir='rtl' className="container mx-auto">
                        <Marks />
                    </div>

                    {/* <img src={Arrows} className='w-[200px] mx-auto my-8 text-zoom' alt="" /> */}

                    <div className='border-2 border-[#403c3c] rounded-3xl mt-12 p-4 py-8 max-w-3xl mx-auto'>


                        <h1 data-aos="fade-down"
                            data-aos-easing="ease-in-out" dir='rtl' className='text-center text-lg md:text-2xl font-medium text-main  max-w-4xl mx-auto'>
                            בתור בעל עסק הזמן שלך והכסף שלך מאוד יקרים.
                            <br />
                            אנחנו לא רוצים לבזבז זמן יקר ומשאבים יקרים על דברים שהם לא חשובים עבור העסק שלך.                      </h1>

                        <h1 data-aos="fade-down"
                            data-aos-easing="ease-in-out" dir='rtl' className='text-center  text-lg md:text-2xl font-bold text-secondary mt-8 max-w-4xl mx-auto'>
                            אנחנו נייצר לך אמינות ברשתות הדיגיטליות
                            <br />
                            ונייצר לך משפך לקוחות איכותי
                        </h1>


                        <h1 data-aos="fade-down"
                            data-aos-easing="ease-in-out" dir='rtl' className='text-center text-lg md:text-2xl font-medium text-main mt-8 max-w-4xl mx-auto'>


                            בעזרת התהליך של moove media
                            אנחנו סוף סוף נוכל להחשף ללקוחות חדשים בצורה מקצועית ולהגדיל את המכירות בעסק                            <br />

                            ובסיום התהליך יהיה לעסק ביסוס דיגיטלי אמין ללקוחות שלנו וזרימת לקוחות שוטפת.


                            <br />

                            <span className='font-extrabold text-secondary'>
                                moove media מזמינה אותך לקחת את העסק שלך כמה צעדים קדימה.                            </span>

                        </h1>
                    </div>
                </div>
            </section>


            {/* <section className='specialBg py-20 '>
                <div className='px-4'>
                    <h1 data-aos="fade-down"
                        data-aos-easing="ease-in-out" dir='rtl' className='text-center text-2xl md:text-4xl font-extrabold text-main mt-16'>
                        תהליך העבודה
                    </h1>
                    <h1 data-aos="fade-down"
                        data-aos-easing="ease-in-out" dir='rtl' className='text-center text-2xl md:text-4xl font-extrabold text-secondary'>
                        שלנו יחד
                    </h1>
                    <div dir='rtl' className="container mx-auto">
                        <Steps />
                    </div>

                </div>
            </section> */}
            <section className='py-20 my-container  '>
                <div className='px-4'>
                    <h1
                        data-aos="fade-down"
                        data-aos-easing="ease-in-out" className='text-center text-2xl md:text-4xl mb-8 font-extrabold'>
                        לקוחות ממליצים
                    </h1>
                </div>

                <SwiperClients />

            </section>





            {/* 
            <section className='specialBg py-20 '>
                <div className='px-4'>
                    <h1 data-aos="fade-down"
                        data-aos-easing="ease-in-out" dir='rtl' className='text-center text-2xl md:text-4xl font-extrabold text-main mt-16'>
                        תיק עבודות
                    </h1>

                    <TiktokGrid />
                </div>
            </section> */}

            {/* <section className='specialBg py-20 '>
                <div className='px-4'>
                    <h1 data-aos="fade-down"
                        data-aos-easing="ease-in-out" dir='rtl' className='text-center text-2xl md:text-4xl font-extrabold text-main mt-16'>
                        על מה אני
                    </h1>
                    <h1 data-aos="fade-down"
                        data-aos-easing="ease-in-out" dir='rtl' className='text-center text-2xl md:text-4xl font-extrabold text-secondary'>
                        שם דגש?
                    </h1>
                    <div dir='rtl' className="container mx-auto">
                        <Marks />
                    </div>

                </div>
            </section> */}


            <section id='form' className='heroBg py-20 specialBg '>
                <div className='px-4'>
                    <MessageLeadForm />

                </div>

                <div className="max-w-screen-xl px-4 py-12 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">

                    <div className="flex justify-center space-x-6 mb-4">
                        <a href="tel:+972546966909" className="bg-dark text-secondary h-12 w-12 rounded-full flex items-center justify-center shadow-xl  duration-300">
                            <IoIosCall size={30} />
                        </a>
                        <a href="https://wa.me/972546966909" className="bg-dark text-secondary h-12 w-12 rounded-full flex items-center justify-center shadow-xl  duration-300" target="_blank" rel="noopener noreferrer">
                            <FaWhatsapp size={30} />
                        </a>
                        <a href="https://www.tiktok.com/@stavsmadar" className="bg-dark text-secondary h-12 w-12 rounded-full flex items-center justify-center shadow-xl  duration-300">
                            <IoLogoTiktok size={30} />
                        </a>
                    </div>

                    <div className="flex justify-center space-x-6">
                        <a href="https://www.instagram.com/moove_media.co.il" className="bg-dark text-secondary h-12 w-12 rounded-full flex items-center justify-center shadow-xl  duration-300">
                            <IoLogoInstagram size={30} />
                        </a>
                        <a href="https://www.facebook.com/StavSmadarVM" className="bg-dark text-secondary h-12 w-12 rounded-full flex items-center justify-center shadow-xl  duration-300">
                            <FaFacebook size={30} />
                        </a>
                        <a href="https://www.youtube.com/@stavsmadar6311" className="bg-dark text-secondary h-12 w-12 rounded-full flex items-center justify-center shadow-xl  duration-300">
                            <IoLogoYoutube size={30} />
                        </a>
                    </div>

                </div>
            </section>
            <div className='mx-auto text-center py-6 bg-white'>
                <a href="https://wa.me/972538250965?text=היי%20אשמח%20לפרטים%20בנוגע%20לבניית%20אתר" dir='rtl' className="mt-8 text-base leading-6 text-center text-mainColor">
                    אתר זה נבנה על ידי Idan Shlomo | בניית אתרים בקוד
                </a>
            </div>

            <LeadForm isOpen={isDialogOpen} onRequestClose={closeDialog} />

        </div>


    )
}

export default HomePage
