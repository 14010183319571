import React from 'react';

const InfoCard = ({ icon: Icon, title, description }) => (
    <div data-aos="fade-down"
        data-aos-easing="ease-in-out">
        <div className='group bg-gradient-to-tr  from-black to-[#333] bg-opacity-50 cursor-default p-8 shadow-2xl min-h-64 flex flex-col border-2 border-[#333]  justify-center items-center  rounded-2xl relative hover:scale-105 duration-300'>
            <div className='mb-2'>
                {/* Assuming Icon is a component that accepts size and className, if not you might need to adjust this */}
                <Icon size={80} className="text-secondary mx-auto m-4" />
                <h1 dir='rtl' className='text-white font-bold text-2xl text-center'>{title}</h1>
            </div>
            <p dir='rtl' className='text-center text-lg text-gray-200'>{description}</p>
        </div>
    </div>
);

export default InfoCard;
