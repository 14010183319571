import React, { useEffect } from 'react';
import HomePage from './components/HomePage';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ReactPixel from 'react-facebook-pixel';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // values from 0 to 3000, with step 50ms
      once: true, // whether animation should happen only once - while scrolling down
    });

    // const pixelId = process.env.REACT_APP_FACEBOOK_PIXEL_ID;
    // console.log('Facebook Pixel ID:', pixelId); // Debugging line
    // if (pixelId) {
    //   const options = {
    //     autoConfig: true, // set pixel's autoConfig
    //     debug: false, // enable logs
    //   };
    //   ReactPixel.init(pixelId, {}, options);
    //   ReactPixel.pageView(); // For tracking page view
    // } else {
    //   console.error('Facebook Pixel ID is not defined');
    // }
  }, []);

  return (
    <div>
      <HomePage />
    </div>
  );
}

export default App;
