import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';


// import required modules
import { FreeMode, Pagination } from 'swiper/modules';
import { Navigation, Autoplay } from 'swiper/modules';

const SwiperClients = () => {


    const clientData = [
        {
            name: "אלירן יהורן",
            feedback: "סתיו היקר הפיק לבית הספר שלנו סרטון תדמית יוצא מן הכלל. בתהליך ישבנו יחד ותכננו את יום הצילום. סתיו דאג לכל מה שצריך, הצילומים עברו באוירה משוחררת, טובה, עם חשיבה יצירתית. לאחר העריכה בחלקים שהיה צורך סתיו דאג לעשות יום צילומים נוסף על מנת שהסרטון ייצא ברמה הכי טובה. וכך היה ✨",
            imageUrl: "path/to/image2.jpg"
        },

        {
            name: "אלעד חגל",
            feedback: "סתיו מקצוען אמיתי! הבין בדיוק מה אנחנו צריכים, הגיע למקום לפני הזמן כדי להכין את כל הציוד, גרם לכולם להרגיש מאוד בנוח בזמן הצילומים ואפילו צילם דברים מעבר לפריימים שחשבנו עליהם. ממליץ בחום 🙌🏼",
            imageUrl: "path/to/image2.jpg"
        },

        {
            name: "עומרי ווגסטף",
            feedback: "סתיו מלווה אותי במשך שש שנים כצלם ועורך וידאו. במהלך הזמן הזה, בכל פרויקט מחדש הוא תמיד מראה מקצועיות גבוהה, דיוק ואכפתיות שאין כמותה! הוא מסוגל להרגיע ולהנעים את האווירה ולהפוך את יום הצילומים לחוויה שגורמת לי להרגיש משוחרר ולהביא את עצמי לידי ביטוי. למרות הלחצים שלפעמים יש במהלך ימי הצילום, סתיו תמיד ממציא את עצמו מחדש ודואג שהכל יתקתק. הוא תמיד קשוב לצרכים שלי ופותר כל בעיה עוד לפני שהיא עולה. סתיו הוא לא רק מקצוען ומוכשר- אלא גם אדם שאכפת לו. אישית, בכל פרויקט מחדש אני מתפעל מרמת האיכות של העבודה שלו. הצילומים שלו חדים מרגשים ומעבירים את המסר! בעיני סתיו הוא מעבר לצלם ועורך – הוא אמן בשילוב מספר סיפורים דרך עדשה. אני ממליץ על סתיו מאוד כצלם, כעורך, כמקצוען שנותן שירות, וכאדם!",
            imageUrl: "path/to/image2.jpg"
        },
        {
            name: "עדן ספינר",
            feedback: "רציתי להגיד תודה רבה על יחס אישי ומיוחד שקיבלתי ממך. תודה על זה שהיית איתי בכל התהליך, גרמת לי לתחושת ביטחון ולהביא לחיים את הוויז׳ן שלי. תודה שלא עזבת, לא הרפית עד שהבנת בידיוק מה אני רוצה ואיך צריך לעשות את זה בדרך היעילה ביותר. סתיו תמיד פתוח לקבל ולהביא רעיונות יצירתיים ומוסיף נפח חשוב לתכנון הראשוני. דבר שהוביל להצלחת הפרויקט האישי שלי. ממליצה מאוד❤️",
            imageUrl: "path/to/image2.jpg"
        },

        {
            name: "זיו רובינשטיין",
            feedback: "את סתיו אני מכיר כבר המון שנים חוץ מזה שהוא בחור סופר אמין ומוכשר הוא עשה לאישתי סרטונים לעסק שלה בתחום הביוטי שפשוט התפוצצו ברשת! הביאו לנו לידים איכותיים בשקלים בודדים ושידרו את השפה של המותג בצורה מקצועית ונעימה. מהניסיון שלי צלמים בשוק יש המון ובתור בעלים של סוכנות שיווק הכרתי לא מעט. סתיו זה חומר גלם אחר, אנושי, יצירתי שמבין לעומק את הצרכים של הלקוח ומפיק חומרים ברמה הכי גבוהה שיש",
            imageUrl: "path/to/image2.jpg"
        },

        {
            name: "בתאל רוסו",
            feedback: "אני ממליצה בחום על סתיו, האיכות של הסרטונים, העריכה הכל היה מדוייק ולשביעות רצוני, סתיו אדם נורא נעים ונותן יחס טוב ואדיב, לכל שינוי כל תיקון היה מענה מדהים! ובכלל הוא אדם מאד מקסים שכיף לעבוד איתו ולקבל ממנו שירות! אני ממליצה בחום!❤️❤️",
            imageUrl: "path/to/image2.jpg"
        },

        {
            name: "אוראל גולדנברג",
            feedback: "אני רוצה להודות על השירות הייחודי והאיכותי שקיבלתי מסתיו, בעידן של היום זה ממש לא מובן מאליו. וברמה המקצועית, בתהליך ובהשגת התוצאות- הכל היה פשוט מרהיב. מאוד אהבתי ונהנתי. תודה!",
            imageUrl: "path/to/image2.jpg"
        },

        {
            name: "איילת בן צבי",
            feedback: "אשמח להמליץ בחום על סתיו סמדר המוכשר אני מפיקה את פסטיבל מחוברות חווית בת מצווה לאימהות ובנות סתיו היה צלם אורח (של כבוד) מספר פעמים בפסטיבלים. הוא ידע לא רק לנצור במצלמה שלו רגעים קסומים אלא הלהיב וביים את המשתתפות, הוא הביא איתו אנרגיה וסחף והלהיב את הבנות ממליצה לא בחום אלא ברותח בהצלחה אלוף 👑🫶",
            imageUrl: "path/to/image2.jpg"
        },
        {
            name: "אביחי פרונט לייף",
            feedback: "ממליץ בחום על סתיו! מעבר לעובדה שמדובר באחד האנשים היותר טובים ונעימים שיצא לי לעבוד איתם - סתיו יורד לפרטים בכל דבר, נותן מענה מדהים ואיכותי, ואי אפשר שלא לשים לב לכמה חשוב לו שהתוצרים יהיו ברמה הכי גבוהה שיש - ממליץ בחום!",
            imageUrl: "path/to/image2.jpg"
        },

        {
            name: "תובל",
            feedback: "סתיו צילם לי קליפ וסרטון תדמית לעסק, היה קשוב לבקשות שלי לפני הצילומים והיה סופר סבלני. הקליפ יצא פשוט מטורף!! הזוויות והצילומים יצרו סיפור שמעביר תחושות וגורם למי שצופה להתחבר בקלות. סתיו הוא מקצוען.",
            imageUrl: "path/to/image2.jpg"
        },

        {
            name: "אנה",
            feedback: "חיפשתי את עורך וידאו שיעזור לי לעשות את הסרט עבור בת מצווה של הבנות שלי. עבדתי עם סתיו שעות ארוכות ויצא לנו סרט מרגש, יפה ומושקע. היה כל כך נעים לעבוד עם סתיו. המון ראיונות, דייקנות בפרטים הקטנים, מקצוענות והרבה סבלנות ורוגע. ממליצה בחום! כמובן שיהיה לי עוד סרט לעשות אני אבוא לסתיו :)",
            imageUrl: "path/to/image2.jpg"
        }




    ];
    return (
        <>
            <Swiper
                autoplay={{ delay: 800, disableOnInteraction: false }}
                slidesPerView={1}
                spaceBetween={30}
                loop
                speed={1000}
                freeMode={true}
                pagination={{
                    clickable: true,
                }}
                modules={[FreeMode, Navigation, Autoplay]}
                className="mySwiper  mx-auto  flex justify-center items-center max-w-[95%]"
                breakpoints={{
                    // when window width is >= 320px (small screen)
                    0: {
                        slidesPerView: 1,
                    },
                    320: {
                        slidesPerView: 1,
                    },
                    // when window width is >= 768px (medium screen)
                    768: {
                        slidesPerView: 2,
                    },
                    // when window width is >= 1024px (large screen)
                    1024: {
                        slidesPerView: 2,
                    },
                }}
            >
                {clientData.map((client, index) => (
                    <SwiperSlide key={index}>
                        <div className="p-6 mt-4 border-2 bg-gradient-to-tr mx-5  from-black to-[#333] bg-opacity-50 rounded-3xl border-[#333] my-auto   max-w-[600px]  justify-center">
                            <div dir='rtl' className="text-center">
                                <h3 className="text-lg md:text-2xl font-extrabold text-secondary">{client.name}</h3>
                                <p className="text-sm md:text-lg font-bold text-main">"{client.feedback}"</p>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
}

export default SwiperClients
